import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import Loader from "../loaders/Loader";

const UpdateEmailModal = ({
  open,
  setOpen,
  handleChange,
  isLoading,
  formError,
  hanldeUpdateEmail,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers={true}>
          <div className="input_field mt-2">
            <label className=" block font-semibold text-black text-14px md:text-18 mb-5">
              Update the email address where you would like to receive email.
            </label>
            <input
              type="text"
              placeholder="Enter Your Email"
              name="email"
              onChange={handleChange}
              className="bg-[#fff] border border-[#EE0000]"
            />
            {formError.formError && (
              <div className="form-eror">{formError.formError}</div>
            )}
          </div>
          <div>
            <p className="text-[11px] mt-6">
              <strong>Note:</strong> You can update your email a maximum of two
              times.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={hanldeUpdateEmail}
            type="button"
            className="btn btn-danger min-w-[130px] md:min-w-[200px] bg-[#EE0000]"
          >
            {isLoading ? <Loader /> : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateEmailModal;
