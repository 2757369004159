import { reactIcons } from "./icons";

import {
  GrowthCalculater,
  Kyc,
  Withdraw,
  Report,
  PurchaseNodes,
  Login,
  RegisterPage,
  AffiliateList,
  Franchise,
  Payout,
  Fractions,
  Rewards,
} from "../pages/pageListAsync";
import TelegramLogin from "../components/Forms/TelegramLogin";

export const sideBarLinks = [
  {
    path: "/",
    title: "Growth Calculator",
    icon: reactIcons.calculater,
    key: true,
    component: <GrowthCalculater />,
  },
  {
    path: "/register",
    title: "Become a Validator",
    icon: reactIcons.userCheck,
    key: true,
    component: <RegisterPage />,
  },
  {
    path: "/login",
    title: "Click here to Login",
    icon: reactIcons.login,
    key: true,
    component: <Login />,
  },
];
export const dashboardSideBar = [
  // {
  //   path: '/report',
  //   title: 'Report',
  //   icon: reactIcons.report,
  //   key: false,
  //   component: <Report />,
  // },
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: reactIcons.dashboard,
    key: false,
    component: <Report />,
  },
  // {
  //   path: '/purchase-node',
  //   title: 'Purchase',
  //   icon: reactIcons.purchase,
  //   key: false,
  //   component: <Purchase />,
  // },
  {
    path: "/purchase-node",
    title: "Purchase",
    icon: reactIcons.purchase,
    key: false,
    component: <PurchaseNodes />,
  },

  {
    path: "/kyc",
    title: "KYC",
    icon: reactIcons.kyc,
    key: false,
    component: <Kyc />,
  },
  {
    path: "/withdraw",
    title: "Withdraw",
    icon: reactIcons.wallet,
    key: false,
    component: <Withdraw />,
  },

  {
    path: "/affiliate",
    title: "Affiliate",
    icon: reactIcons.affiliate,
    key: false,
    component: (
      // <PrivateRouteForAffilate>
      <AffiliateList />
      // </PrivateRouteForAffilate>
    ),
  },
  {
    path: "/franchise",
    title: "Franchise",
    icon: reactIcons.franchise,
    key: "",
    component: (
      // <PrivateRouteFranchise>
      <Franchise />
      // </PrivateRouteFranchise>
    ),
  },
  {
    path: "/payout",
    title: "Banking & Withdrawals",
    icon: reactIcons.bank,
    key: "",
    component: (
      // <PrivateRouteFranchise>
      <Payout />
      // </PrivateRouteFranchise>
    ),
  },
  {
    path: "/fractions",
    title: "Fractions",
    icon: reactIcons.fraction,
    key: "",
    component: <Fractions />,
  },
  // {
  //   path: "/rewards",
  //   title: "Rewards",
  //   icon: reactIcons.reward,
  //   key: "",
  //   component: <Rewards />,
  // },
];
export const socialLinks2 = [
  {
    title: "Twitter",
    icon: reactIcons.twitter,
    url: "https://x.com/MasterStrokeTec",
  },
  {
    title: "facebook",
    icon: reactIcons.fb,
    url: "https://www.facebook.com/MasterstrokeTechnosoft",
  },
  {
    title: "Instagram",
    icon: reactIcons.instagram,
    url: "https://www.instagram.com/masterstroketechnosoft/",
  },
  // {
  //   title: 'Github',
  //   icon: reactIcons.git,
  //   url: 'https://x.com',
  // },
];
export const credentials = [
  { logo: "/images/autograph.png", title: "Signature" },
  { logo: "/images/gps.png", title: "Address" },
  { logo: "/images/stamp.png", title: "Commission and Seals" },
  { logo: "/images/approved.png", title: "Digital Certifcate" },
  {
    logo: "/images/achievements.png",
    title: "Certifications and Achievements",
  },
];

export const footerLinks = [
  { link: "/about-us#about-us", title: "About US" },
  { link: "/terms#terms", title: "Terms & Conditions" },
  { link: "/privacy#privacy", title: "Privacy Policy" },
  { link: "/cancellation#cancellation", title: "Cancellation & Refund" },
  { link: "/shipping#shipping", title: "Shipping & Delivery" },
  { link: "/contact#contact", title: "Contact Us" },
];

export const timeZones = [
  "(GMT-11:00) American Samoa ",
  "(GMT-11:00) International Date Line West ",
  "(GMT-11:00) Midway Island ",
  "(GMT-10:00) Hawaii ",
  "(GMT-09:00) Alaska ",
  "(GMT-08:00) Pacific Time (US &amp; Canada) ",
  "(GMT-08:00) Tijuana ",
  "(GMT-07:00) Arizona ",
  "(GMT-07:00) Chihuahua ",
  "(GMT-07:00) Mazatlan ",
  "(GMT-07:00) Mountain Time (US &amp; Canada) ",
  "(GMT-06:00) Central America ",
  "(GMT-06:00) Central Time (US &amp; Canada) ",
  "(GMT-06:00) Guadalajara ",
  "(GMT-06:00) Mexico City ",
  "(GMT-06:00) Monterrey ",
  "(GMT-06:00) Saskatchewan ",
  "(GMT-05:00) Bogota ",
  "(GMT-05:00) Eastern Time (US &amp; Canada) ",
  "(GMT-05:00) Indiana (East) ",
  "(GMT-05:00) Lima ",
  "(GMT-05:00) Quito ",
  "(GMT-04:30) Caracas ",
  "(GMT-04:00) Atlantic Time (Canada) ",
  "(GMT-04:00) Georgetown ",
  "(GMT-04:00) La Paz ",
  "(GMT-04:00) Santiago ",
  "(GMT-03:30) Newfoundland ",
  "(GMT-03:00) Brasilia ",
  "(GMT-03:00) Buenos Aires ",
  "(GMT-03:00) Greenland ",
  "(GMT-02:00) Mid-Atlantic ",
  "(GMT-01:00) Azores ",
  "(GMT-01:00) Cape Verde Is. ",
  "(GMT+00:00) Casablanca ",
  "(GMT+00:00) Dublin ",
  "(GMT+00:00) Edinburgh ",
  "(GMT+00:00) Lisbon ",
  "(GMT+00:00) London ",
  "(GMT+00:00) Monrovia ",
  "(GMT+00:00) UTC ",
  "(GMT+01:00) Amsterdam ",
  "(GMT+01:00) Belgrade ",
  "(GMT+01:00) Berlin ",
  "(GMT+01:00) Bern ",
  "(GMT+01:00) Bratislava ",
  "(GMT+01:00) Brussels ",
  "(GMT+01:00) Budapest ",
  "(GMT+01:00) Copenhagen ",
  "(GMT+01:00) Ljubljana ",
  "(GMT+01:00) Madrid ",
  "(GMT+01:00) Paris ",
  "(GMT+01:00) Prague ",
  "(GMT+01:00) Rome ",
  "(GMT+01:00) Sarajevo ",
  "(GMT+01:00) Skopje ",
  "(GMT+01:00) Stockholm ",
  "(GMT+01:00) Vienna ",
  "(GMT+01:00) Warsaw ",
  "(GMT+01:00) West Central Africa ",
  "(GMT+01:00) Zagreb ",
  "(GMT+02:00) Athens ",
  "(GMT+02:00) Bucharest ",
  "(GMT+02:00) Cairo ",
  "(GMT+02:00) Harare ",
  "(GMT+02:00) Helsinki ",
  "(GMT+02:00) Istanbul ",
  "(GMT+02:00) Jerusalem ",
  "(GMT+02:00) Kyiv ",
  "(GMT+02:00) Pretoria ",
  "(GMT+02:00) Riga ",
  "(GMT+02:00) Sofia ",
  "(GMT+02:00) Tallinn ",
  "(GMT+02:00) Vilnius ",
  "(GMT+03:00) Baghdad ",
  "(GMT+03:00) Kuwait ",
  "(GMT+03:00) Minsk ",
  "(GMT+03:00) Moscow ",
  "(GMT+03:00) Nairobi ",
  "(GMT+03:00) Riyadh ",
  "(GMT+03:00) St. Petersburg ",
  "(GMT+03:00) Volgograd ",
  "(GMT+03:30) Tehran ",
  "(GMT+04:00) Abu Dhabi ",
  "(GMT+04:00) Baku ",
  "(GMT+04:00) Muscat ",
  "(GMT+04:00) Tbilisi ",
  "(GMT+04:00) Yerevan ",
  "(GMT+04:30) Kabul ",
  "(GMT+05:00) Ekaterinburg ",
  "(GMT+05:00) Islamabad ",
  "(GMT+05:00) Karachi ",
  "(GMT+05:00) Tashkent ",
  "(GMT+05:30) Chennai ",
  "(GMT+05:30) Kolkata ",
  "(GMT+05:30) Mumbai ",
  "(GMT+05:30) New Delhi ",
  "(GMT+05:30) Sri Jayawardenepura ",
  "(GMT+05:45) Kathmandu ",
  "(GMT+06:00) Almaty ",
  "(GMT+06:00) Astana ",
  "(GMT+06:00) Dhaka ",
  "(GMT+06:00) Novosibirsk ",
  "(GMT+06:00) Urumqi ",
  "(GMT+06:30) Rangoon ",
  "(GMT+07:00) Bangkok ",
  "(GMT+07:00) Hanoi ",
  "(GMT+07:00) Jakarta ",
  "(GMT+07:00) Krasnoyarsk ",
  "(GMT+08:00) Beijing ",
  "(GMT+08:00) Chongqing ",
  "(GMT+08:00) Hong Kong ",
  "(GMT+08:00) Irkutsk ",
  "(GMT+08:00) Kuala Lumpur ",
  "(GMT+08:00) Perth ",
  "(GMT+08:00) Singapore ",
  "(GMT+08:00) Taipei ",
  "(GMT+08:00) Ulaan Bataar ",
  "(GMT+09:00) Osaka ",
  "(GMT+09:00) Sapporo ",
  "(GMT+09:00) Seoul ",
  "(GMT+09:00) Tokyo ",
  "(GMT+09:00) Yakutsk ",
  "(GMT+09:30) Adelaide ",
  "(GMT+09:30) Darwin ",
  "(GMT+10:00) Brisbane ",
  "(GMT+10:00) Canberra ",
  "(GMT+10:00) Guam ",
  "(GMT+10:00) Hobart ",
  "(GMT+10:00) Magadan ",
  "(GMT+10:00) Melbourne ",
  "(GMT+10:00) Port Moresby ",
  "(GMT+10:00) Solomon Is. ",
  "(GMT+10:00) Sydney ",
  "(GMT+10:00) Vladivostok ",
  "(GMT+11:00) New Caledonia ",
  "(GMT+12:00) Auckland ",
  "(GMT+12:00) Fiji ",
  "(GMT+12:00) Kamchatka ",
  "(GMT+12:00) Marshall Is. ",
  "(GMT+12:00) Wellington ",
  "(GMT+13:00) Nuku'alofa ",
  "(GMT+13:00) Samoa ",
  "(GMT+13:00) Tokelau Is. ",
];

export const selectOption = [
  { name: "Active", value: "accepted" },
  { name: "Inactive", value: "rejected" },
];
export const kycSelectOption = [
  { name: "Accept KYC request", value: "Accepted", disabled: false },
  { name: "Reject KYC request", value: "Rejected", disabled: false },
  { name: "Pending", value: "Pending", disabled: true },
];

export const verifyStatusList = [
  { label: "All", value: "" },
  { label: "Verified", value: "verified" },
  { label: "Not Verified", value: "notVerified" },
];
export const socialConnect = [
  {
    title: "Connect Your Facebook",
    img: "/images/reward/facebook.png",
  },
  {
    title: "Connect Your X",
    img: "/images/reward/x.png",
  },
  {
    title: "Connect Your Telegram",
    img: "/images/reward/telegram.png",
    component: <TelegramLogin />,
  },
];
export const activityList = [
  {
    title: "Complete Your KYC and Earn 100 MSTC Reward",
  },
  {
    title: "Click to share the new post on facebook and earn 100 MSTC Reward.",
  },
  {
    title: "Vote new poll on our telegram channel and earn 150 MSTC Reward.",
  },
];
