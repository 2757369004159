import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import Loader from "../loaders/Loader";

// const initialState = {
//   upi: '',
//   image: '',
// };
const UpdateAddressModal = ({
  open,
  setOpen,
  otpOpen,
  setOtpOpen,
  handleChange,
  isLoading,
  formError,
  hanldeUpdateWallet,
  hanldeSendCode,
}) => {
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // try {
  //   //   setFormError({});
  //   //   await QrCodeValidation.validate(form, {
  //   //     abortEarly: false,
  //   //   });
  //   //   const res = await api.addQrCode(form);
  //   //   if (res.data.status === 'success') {
  //   //     setIsLoading(false);
  //   //     toast.success('Added Successfully');
  //   //     _setImage('');
  //   //     handleCloseModal();
  //   //   } else {
  //   //     setIsLoading(false);
  //   //   }
  //   // } catch (error) {
  //   //   if (isYupError(error)) {
  //   //     setFormError(parseYupError(error));
  //   //   } else {
  //   //     toast.error(error.data.message);
  //   //   }

  //   //   setIsLoading(false);
  //   // }
  // };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers={true}>
          <div className="input_field mt-2">
            <label className=" block font-semibold text-black text-14px md:text-18 mb-5">
              Update the wallet address where you would like to receive your
              rewards.
            </label>
            <input
              type="text"
              placeholder="Enter Your Wallet Address (0x)...."
              name="walletAddress"
              onChange={handleChange}
              className="bg-[#fff] border border-[#EE0000] mb-5"
            />
            {formError.walletAddress && (
              <div className="form-eror">{formError.walletAddress}</div>
            )}
            {otpOpen ? (
              <>
                <input
                  type="text"
                  placeholder="Enter Your OTP"
                  name="mobileVerificationOtp"
                  onChange={handleChange}
                  className="bg-[#fff] border border-[#EE0000]"
                />
                {formError.mobileVerificationOtp && (
                  <div className="form-eror">
                    {formError.mobileVerificationOtp}
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="text-[11px] mt-6">
              <strong>Note:</strong> Please ensure that the wallet address you
              are providing is accurate and active. Once submitted, it will be
              reviewed and updated by our team. Incorrect wallet addresses may
              result in failed transactions or loss of funds. Double-check your
              entry before proceeding.
              <br />
              You can update your wallet address a maximum of two times.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          {otpOpen ? (
            <button
              onClick={hanldeUpdateWallet}
              type="button"
              className="btn btn-danger min-w-[130px] md:min-w-[200px] bg-[#EE0000]"
            >
              {isLoading ? <Loader /> : "Submit"}
            </button>
          ) : (
            <button
              onClick={hanldeSendCode}
              type="button"
              className="btn btn-danger min-w-[130px] md:min-w-[200px] bg-[#EE0000]"
            >
              {isLoading ? <Loader /> : "Send Code"}
            </button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateAddressModal;
