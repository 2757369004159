import React from "react";
import { useNavigate } from "react-router-dom";
import { reactIcons } from "../../utils/icons";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const Header = ({ toggleSidebar, isVerify }) => {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state?.user || {});
  const getToken = Cookies.get(`accessToken`);
  return (
    <div className="h-[80px] w-full z-[9999] bg-black border-b border-[#FFE3E3]">
      <div className="flex lg:items-center justify-between gap-3 cursor-pointer px-3 py-3">
        <div
          onClick={() => {
            const cookeiData = Cookies.get(`accessToken`);
            if (cookeiData && !isVerify) {
              navigate("/kyc");
            } else if (cookeiData) {
              navigate("/dashboard");
            } else {
              navigate("/");
            }
          }}
          className="flex items-center gap-2"
        >
          <div>
            <img src="/images/logo/main.png" alt="logo" className="w-[55px]" />
          </div>

          <div>
            <img src="/images/logo/sad.png" alt="logo" className="w-[180px]" />
            <p className="text-[8px] text-white mt-1  text-center">
              YOUR PATH TO A DECENTRALIZED FUTURE
            </p>
          </div>
        </div>
        {getToken && (
          <div
            className="hidden lg:flex items-center"
            onClick={() => navigate("/profile")}
          >
            <span className="bg-white p-2 rounded-full mr-2">
              {reactIcons.user}
            </span>
            <p className="text-white mr-2 font-semibold">
              {userData?.firstname + " " + userData?.lastname || "N/A"}{" "}
            </p>
          </div>
        )}
        {/* {getToken && (
          <div className="flex items-center">
            <p className="text-white mr-2">Referral Link: </p>
            <CopyToClipboard text={link} onCopy={handleCopyLink}>
              <span className="border border-gray-300 rounded-md p-2 gap-2 cursor-pointer flex items-center text-white">
                {link.slice(0, 35) + '...' || 'N/A'}
                {reactIcons.copy}
              </span>
            </CopyToClipboard>
          </div>
        )} */}
        <div className="pr-1 sm:flex lg:hidden">
          <button
            onClick={toggleSidebar}
            className="w-10 h-10 flex-center text-white bg-common rounded-full text-3xl"
          >
            {reactIcons.toggle}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Header;
