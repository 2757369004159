import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { patchReq } from "../../utils/apiHandlers";
import { fetchUserData } from "../../redux/Slice/User/user";
import { isYupError, parseYupError } from "../../utils/Yup";
import { walletEmail } from "../../utils/validation";
import { reactIcons } from "../../utils/icons";
import UpdateEmailModal from "../modals/UpdateEmailModal";

const EmailUpdate = ({ handleEmailLogs }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: "",
  });
  const [formError, setFormError] = useState({
    email: "",
  });

  // input value update function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  // update email address function
  const hanldeUpdateEmail = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await walletEmail.validate(form, {
        abortEarly: false,
      });

      const res = await patchReq("/users/me", form);
      const { status, error } = res;
      if (status) {
        setIsLoading(false);
        setOpen(false);
        dispatch(fetchUserData());
        toast.success("Email Updated Successfully");
        setForm({
          email: "",
        });
        setFormError({
          email: "",
        });
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else {
        toast.error(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="gradient-background text-white p-2.5 rounded-lg flex items-center justify-center gap-2 border text-22 font-normal absolute top-[2px] right-[2px] bottom-[2px] h-[46px] w-[46px]"
      >
        {reactIcons.edit}
      </button>
      <button
        type="button"
        onClick={handleEmailLogs}
        className="w-full text-right text-[11px] text-[#ee0000] underline mt-2 leading-none"
      >
        View Email Update Logs
      </button>

      <UpdateEmailModal
        setOpen={setOpen}
        open={open}
        isLoading={isLoading}
        formError={formError}
        handleChange={handleChange}
        hanldeUpdateEmail={hanldeUpdateEmail}
      />
    </>
  );
};

export default EmailUpdate;
