import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import { Fragment, useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import { dashboardSideBar, sideBarLinks } from "./utils/constants";
import ReportTwo from "./pages/Report/ReportTwo";
import Report from "./pages/Report";
import ReportOne from "./pages/Report/ReportOne";
import DefaultDashboard from "./layout/DefaultDashboard";
import PublicRoutes from "./layout/PublicRoutes";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  NotFound,
  Dashboard,
  Login,
  RegisterPage,
  ForgotPassword,
  Purchase,
  About,
  Privacy,
  Terms,
  Cancellation,
  Shipping,
  Contact,
  CoinEarn,
  FractionsEarn,
} from "./pages/pageListAsync";
import UserProfile from "./components/Profile";
import TwitterOauth from "./pages/TwitterOauth";
function App() {
  const location = useLocation();
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state?.user || {});
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  const getAccessToken = () => {
    const params = new URLSearchParams(location.search);
    const data = params.get("accessToken");
    if (data) {
      Cookies.set("accessToken", data);
    }
  };
  useEffect(() => {
    getAccessToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const CookiesData = Cookies.get("accessToken");
    const network = process.env.REACT_APP_NETWORK === "mainnet";
    console.log(network, "network");
    if (
      userData &&
      network &&
      Object.keys(userData).length > 0 &&
      !userData?.isVerified &&
      CookiesData
    ) {
      navigate("/kyc");
      console.log("heelo");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userData?.isVerified]);

  return (
    <>
      <ToastContainer toastStyle={{ zIndex: 9999 }} />
      <Routes>
        <Route
          index
          path="register"
          element={
            <PublicRoutes>
              {" "}
              <RegisterPage />
            </PublicRoutes>
          }
        ></Route>

        <Route
          path="login"
          element={
            <PublicRoutes>
              {" "}
              <Login />
            </PublicRoutes>
          }
        ></Route>
        <Route
          path="forgot-password"
          element={
            <PublicRoutes>
              {" "}
              <ForgotPassword />
            </PublicRoutes>
          }
        ></Route>
        <Route
          path="/"
          element={
            <PublicRoutes>
              <DefaultDashboard />
            </PublicRoutes>
          }
        >
          {sideBarLinks.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              element={<Fragment>{item.component}</Fragment>}
            ></Route>
          ))}
          <Route path="/about-us" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<Report />}></Route>
          <Route path="coin-earn" element={<CoinEarn />}></Route>
          <Route
            path="fraction-earn/:date/:nodeId"
            element={<FractionsEarn />}
          ></Route>
          <Route index path="purchase-receipt" element={<Purchase />}></Route>
          <Route index path="profile" element={<UserProfile />}></Route>
          {/* <Route index path="dashboard" element={<DashboardLanding />}></Route> */}

          {/* <Route path="/report/:id?" element={<Report />}></Route> */}
          <Route path="/report/:id" element={<ReportOne />}></Route>
          <Route path="/report/:id/:1231" element={<ReportTwo />}></Route>
          <Route path="/twitter-oauth/code" element={<TwitterOauth />}></Route>
          {dashboardSideBar.map(
            (item, index) =>
              item.title !== "Dashboard" && (
                <Route
                  key={index}
                  path={item.path}
                  element={<Fragment>{item.component}</Fragment>}
                ></Route>
              )
          )}

          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
