import React, { useEffect, useState } from "react";

import { Pagination } from "@mui/material";

import { reactIcons } from "../../utils/icons";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { getAuthReq } from "../../utils/apiHandlers";
const headings = [
  "S.No.",
  "Node ID",
  "Block Validated Time",
  "Block ID",
  "Block Hash",
];
const ReportOne = () => {
  const take = 5;

  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [listData, setListData] = useState({
    count: 0,
    data: [],
  });
  const location = useLocation();
  const date = moment(location?.state.date).format("YYYY-MM-DD");
  const getNodeID = location && location?.state?.validatorNodeId;
  const handlePage = (e, page) => {
    e.preventDefault();
    setPageNumber(page);
  };
  const fetchReportBydate = async (skip, take) => {
    const res = await getAuthReq(
      `/reports/date?&date=${date}&skip=${skip}&take=${take}&nodeId=${
        getNodeID && getNodeID
      }`
    );
    const { status, data } = res;
    if (status) {
      setListData({ count: data?.count, data: data.data });
    }
  };

  useEffect(() => {
    fetchReportBydate((pageNumber - 1) * take, take);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, take]);
  return (
    <div className="h-[100%]">
      <div className="flex items-center justify-between mb-3 md:mb-10">
        <h4 className="cursor-pointer text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px] flex items-center gap-2">
          <button
            className="btn-gradient w-8 h-8 border border-white rounded-full flex items-center justify-center text-14"
            onClick={() => navigate(-1)}
          >
            {reactIcons.leftArrow}
          </button>
          Report
        </h4>
      </div>

      <div className="overflow-x-auto custom-scroll">
        <table className="text-xs leading-6 font-normal min-w-[1290px] w-full opacity-[100%] duration-1000">
          <thead>
            <tr className="bg-gradient-red rounded-lg text-white">
              {headings.map((item, index) => {
                return (
                  <th key={item} className="group">
                    <div
                      className={`py-1 my-1 flex items-center justify-center text-left font-semibold ${
                        index === headings.length - 1
                          ? "border-r-0"
                          : "border-white border-r"
                      }`}
                    >
                      {item}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listData.data.length > 0 ? (
              listData.data &&
              listData.data.map((item, index) => {
                return (
                  <tr
                    className=" bg-[#181B1D] h-[42px] border-2 border-[#393939] text-white mb-2"
                    key={index}
                  >
                    <td>
                      <div className="text-center border-r border-white">
                        {" "}
                        {(pageNumber - 1) * take + index + 1}
                      </div>
                    </td>
                    <td>
                      <div className="text-center border-r border-white">
                        {item?.validatorNodeId}
                      </div>{" "}
                    </td>
                    <td>
                      <div className="text-center border-r border-white">
                        {moment(item?.timestamp).format("hh:mm:ss a")}
                      </div>{" "}
                    </td>
                    <td>
                      <div className="text-center border-r border-white">
                        {item?.blockNumber}
                      </div>{" "}
                    </td>
                    <td>
                      <div className="text-center">{item?.txHash || "N/A"}</div>{" "}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-white text-center py-4">
                  Report Not Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="py-4 flex justify-end items-center">
        <Pagination
          count={Math.ceil(parseInt(listData.count / take))}
          onChange={(e, page) => {
            handlePage(e, page);
            setPageNumber(page);
          }}
          page={pageNumber}
          hideNextButton={
            pageNumber >= Math.ceil(parseInt(listData.count / take))
          }
          hidePrevButton={pageNumber <= 1}
          sx={{
            "& .MuiPaginationItem-root": {
              background: "transparent",
              color: "white",
              "&:focus": {
                background: "none",
                outline: "none",
                boxShadow: "none",
              },
            },
            "& .MuiPaginationItem-previousNext": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "white",
              "&:hover": {
                background:
                  "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              },
              "&:focus": {
                background:
                  "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
                outline: "none",
                boxShadow: "none",
              },
            },
            "& .Mui-selected": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "50%",

              "&:focus": {
                background:
                  "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
                outline: "none",
                boxShadow: "none",
              },
            },
          }}
        />
        {/* <Pagination
          count={Math.ceil(parseInt(listData.count / take))}
          onChange={(e, page) => {
            handlePage(e, page);
            setPageNumber(page);
          }}
          // count={Math.ceil(
          //   parseInt(listData.count / take) +
          //     parseInt(listData.count % take === 0 ? 0 : 1),
          // )}
          page={pageNumber}
          color="primary"
          sx={{
            "& .Mui-selected": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "white",
            },
            "& .MuiPaginationItem-root": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "white",
            },
            "& .MuiPaginationItem-root:hover": {
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              color: "white",
            },
          }}
        /> */}
      </div>
    </div>
  );
};
export default ReportOne;
