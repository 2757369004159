import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { postAuthReq } from "../../utils/apiHandlers";
import { useSelector } from "react-redux";
// import toast from "react-hot-toast";
// import { init } from "@/redux/actions";
// import { setAuthCookie } from "@/utils/apiHandlers";
// import { useDispatch } from "react-redux";
// import Cookies from "js-cookie";
// import { useSession } from "@/utils/actionContext";
// import { activity } from "@/utils/trackActivity";

const TelegramLogin = () => {
  const { userData } = useSelector((state) => state?.user || {});
  //   const dispatch = useDispatch();
  //   const { createActivitTrackSession } = useSession();
  const teleLogin = localStorage.getItem("teleLog");
  const handleTelegramAuth = async (telegramData) => {
    if (telegramData) {
      try {
        console.log(telegramData, "telegramData");
        const payload = {
          ...telegramData,
          userId: userData?.id,
        };
        const res = await postAuthReq("/auth/telegram", payload);
        const { status } = res;
        if (status) {
          toast.success("Connected Successfully");
        } else {
          if (Array.isArray(res?.error?.message)) {
            toast.error(res?.error?.message[0]);
            toast.error(res?.error?.message);
          }
          toast.error(res?.error?.message);
        }
      } catch (error) {
        console.error("Error during Telegram authentication:", error);
      }
    }
  };

  useEffect(() => {
    window.onTelegramAuth = handleTelegramAuth;
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.async = true;
    script.setAttribute("data-telegram-login", "MstBlockchainBot");
    script.setAttribute("data-size", "large");
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.setAttribute("data-request-access", "write");
    script.setAttribute("data-userpic", "false");
    script.onload = () => {
      console.log("Telegram Widget Loaded");
      const iframe = document.getElementById("telegram-login-MstBlockchainBot");
      if (iframe) {
        const btn = iframe.getElementsByClassName("tgme_widget_login_button");
        console.log(btn);
      }
    };
    const container = document.getElementById("telegram-login-widget");
    container?.appendChild(script);
    return () => {
      if (container?.contains(script)) {
        container.removeChild(script);
      }
    };
    // eslint-disable-next-line
  }, [teleLogin === "true"]);

  useEffect(() => {
    window.TelegramLoginWidget = {
      onLogin: (user) => {
        handleTelegramAuth(user);
      },
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="text-black flex justify-center items-center">
      <div id="telegram-login-widget"></div>
    </div>
  );
};

export default TelegramLogin;
